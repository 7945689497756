'use strict';

var processInclude = require('./util');

$(document).ready(function () {
  $('.show-more-button').on('click', () => {
    $('.show-more-button').addClass('d-none');
    $('.show-less-button').removeClass('d-none');
    $('.full-text-displayed').addClass('active');
  });

  $('.show-less-button').on('click', () => {
    $('.show-more-button').removeClass('d-none');
    $('.show-less-button').addClass('d-none');
    $('.full-text-displayed').removeClass('active');
  });
});
